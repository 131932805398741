import * as React from 'react';
import { Input } from './Input';
import { Button } from './Button';
import { Title } from './Title';

import bg from '../images/gerb.svg';

const Form = () => {
    return (
        <form
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/success"
            className="form"
            style={{ backgroundImage: `url(${bg})` }}
            name="consultant"
            method="post"
        >
            <div className="form__container">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="consultant" />
                <Title className="h1 form__title" level="h2">
                    Не знаете с чего начать?
                </Title>
                <p className="form__text">
                    Оставьте заявку, наш специалист свяжется с вами и поможет в решении вашего вопроса!
                </p>
                <div className="form__items">
                    <Input placeholder="Ваше имя" name="name" required={true} />
                    <Input placeholder="Телефон или email" name="contacts" required={true} />
                    <Button>Отправить</Button>
                </div>
                <div className="form__prava">
                    Нажимая кнопку "Отправить", я даю согласие на{' '}
                    <a href="/Согласие_на_обработку_персональных_данных.docx" target="_blank">
                        обработку персональных данных
                    </a>
                </div>
            </div>
        </form>
    );
};

export { Form };
