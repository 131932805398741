import * as React from 'react';
import { Link } from 'gatsby';

const Breadcrumbs = ({ crumbs }) => {
    return (
        <div className="container">
            <nav aria-label="breadcrumbs">
                <ul className="breadcrumbs">
                    {crumbs.map(([link, name]) => {
                        if (link) {
                            return (
                                <li key={name} className="breadcrumbs__item">
                                    <Link to={link} className="breadcrumbs__link">
                                        {name}
                                    </Link>
                                </li>
                            );
                        }
                        return (
                            <li key={name} className="breadcrumbs__item" aria-current="page">
                                <span>{name}</span>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};

export { Breadcrumbs };
