import React from 'react';
import { Link } from 'gatsby';

const CardBlog = ({ slug, title, date, children }) => {
    return (
        <article className="card-blog" itemScope itemType="http://schema.org/Article">
            <h2 className="h2 card-blog__title">
                <Link to={slug} itemProp="url">
                    <span itemProp="headline">{title}</span>
                </Link>
            </h2>
            <div className="card-blog__text">{children}</div>
            <div className="card-blog__date">{date}</div>
        </article>
    );
};

export { CardBlog };
