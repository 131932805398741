import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { Form } from '../components/Form';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Title } from '../components/Title';
import { Seo } from '../components/Seo';
import { CardBlog } from '../components/CardBlog';

function IndexSite({ data, location }) {
    const posts = data.allMarkdownRemark.nodes;

    return (
        <Layout location={location}>
            <Breadcrumbs
                crumbs={[
                    ['/', 'Главная'],
                    [null, 'Блог'],
                ]}
            />
            <div className="container section-margin">
                <Title className="h1" level="h1">
                    Блог
                </Title>
                <div className="wrapper-card-blog">
                    {posts.map((post) => (
                        <CardBlog slug={post.fields.slug} title={post.frontmatter.title} date={post.frontmatter.date}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: post.frontmatter.description || post.excerpt,
                                }}
                                itemProp="description"
                            />
                        </CardBlog>
                    ))}
                </div>
            </div>
            <Form />
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return <Seo title="Блог - ГЛАВТОРГИ" description="Все о тендерах" />;
}

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                }
            }
        }
    }
`;
